<template>
  <div class="categories">
    <Navbar/>  
    <div class="container">
        <CategorieTitle>
            <template #maintitle>
                Бизнес-школы
            </template>
        </CategorieTitle>

        <div class="row school-row" >
            <SchoolCard :url="'/schools/business-schools/skema'" :background="'image-skema'">
                <template #title>
                    Skema
                </template>
                <template #subtitle>
                    топ-6 бизнес-школ Франции с кампусами в Париже, Лилле и София-Антиполис
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/business-schools/neoma'" :background="'image-neoma'">
                <template #title>
                    Neoma
                </template>
                <template #subtitle>
                    топ-9 в рейтинге. Кампусы в Париже, Руане и Реймсе.
                </template>
            </SchoolCard>
        </div>

        <div class="row school-row" >
            <SchoolCard :url="'/schools/business-schools/mbs'" :background="'image-bs'">
                <template #title>
                    Montpellier BS
                </template>
                <template #subtitle>
                    топ-13 бизнес-школ Франции. Кампус в Мотнпелье.
                </template>
            </SchoolCard>

            <SchoolCard :url="'/schools/business-schools/pbs'" :background="'image-pbs'">
                <template #title>
                    Paris BS
                </template>
                <template #subtitle>
                    топ 23. Кампус в 13-м округе Парижа.
                </template>
            </SchoolCard>
        </div>

        <div class="row school-row">
            <SchoolCard :url="'/schools/business-schools/ipag'" :background="'image-ipag'">
                <template #title>
                    IPAG
                </template>
                <template #subtitle>
                    топ 25 бизнес-школ Франции. Кампусы в Париже и Ницце.
                </template>
            </SchoolCard>
        </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue'
import SchoolCard from '@/components/categories/slots/SchoolCard.vue'
import CategorieTitle from '@/components/categories/slots/CategorieTitle.vue'


export default {
  name: 'BusinessSchools',
  components: {
    Navbar,
    SchoolCard,
    CategorieTitle
  }
}
</script>
